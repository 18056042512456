


























































































































































































































































.el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.classCheckDetail {
  // th{
  //   background-color: #ffffff !important;
  //   color:#000 !important;
  //   border-top: 1px solid rgb(92, 107, 232);
  // }
  .btn-box {
    text-align: center;
    margin-top: 30px;
  }
  .el_form {
    .form-inline {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
  }
  .framePage-body {
    width: 100%;
    overflow-y: auto;
  }
  .el-div {
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    border-bottom: 1px solid #dcdfe6;
  }
  .el-textarea {
    .el-textarea__inner {
      min-height: 100px !important;
      resize: none;
    }
  }
}
